import React from 'react'

import styled from 'styled-components'

const ViewMoreBtn = ({btnText}) => (
  <StyledViewMoreBtn className='view-more-btn'>{btnText}</StyledViewMoreBtn>
)

const StyledViewMoreBtn = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  display: inline-flex;
  align-items: center;
  transition: ${(props) => props.theme.transforms.link};
  cursor: pointer;
  white-space: nowrap;

  &::before {
    content: '';
    display: inline-block;
    width: 28px;
    height: 2px;
    background-color: currentColor;
    margin-right: 11px;
    margin-top: 1px;
    transition: width 0.2s ease;
  }
`

export default ViewMoreBtn
