import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { mapEdgesToNodes } from '../lib/helpers';
import { useLoadMore } from '../lib/hooks';
import { breakpoints } from '../theme/mediaQueries';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageFooter from '../components/PageFooter';
import ReviewCard from '../components/Review/ReviewCard';
import OutlineBtnLg from '../components/Buttons/OutlineBtnLg';

const ReviewsPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const { viewReviews, readReview, loadMoreReviews } = data.reviewsDictionary;

  const reviewsArray = mapEdgesToNodes(data.reviews);
  const numOfItemsToDisplay = 2;

  const [reviewsList, loadMore, hasMore, setLoadMore] = useLoadMore(
    reviewsArray,
    numOfItemsToDisplay
  );

  const reviewsListDisplay = reviewsList.map((item) => (
    <StyledCardWrapper key={item.id}>
      <ReviewCard review={item} lang={pageContext.locale} btnText={readReview} />
    </StyledCardWrapper>
  ));

  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.fluid} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>
      <StyledReviewsContainer>{reviewsListDisplay}</StyledReviewsContainer>
      <PageFooter>
        {hasMore ? (
          <OutlineBtnLg btnText={loadMoreReviews} as="button" handleClick={handleLoadMoreClick} />
        ) : (
          <OutlineBtnLg btnText={loadMoreReviews} as="button" disabled />
        )}
      </PageFooter>
    </Layout>
  );
};

export const query = graphql`
  query ReviewsPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-2b9d8163-c1f8-5a3b-845d-4de6425d6303" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    reviewsDictionary: sanityDictionaryReview {
      viewReviews {
        _type
        de
        en
      }
      readReview {
        _type
        de
        en
      }
      loadMoreReviews {
        _type
        de
        en
      }
    }
    reviews: allSanityReview(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          publishedAt
          slug {
            current
          }
          source
          _rawQuote
        }
      }
    }
  }
`;

const StyledReviewsContainer = styled(Container)`
  margin-top: 4.875rem;
  margin-bottom: 0;

  & > div:nth-child(even) {
    @media (min-width: ${breakpoints.md}px) {
      & .card-row {
        transform: translateX(-1.5rem);
      }
      & .card-left {
        order: 2;
        & > .card {
          padding-left: 4.5rem;
        }
      }
      & .card-right {
        order: 1;
        transform: translate(3rem, 3rem);
      }
    }
  }
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 5rem;
  }
`;

export default localize(ReviewsPage);
