import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../theme/mediaQueries';

const PageSectionTitle = ({ title, color }) => (
  <StyledPageSectionTitle color={color}>{title}</StyledPageSectionTitle>
);

const StyledPageSectionTitle = styled.h2`
  color: ${(props) =>
    props.color === 'white' ? props.theme.colors.white : props.theme.colors.primary};
  font-size: 2rem;
  font-weight: 200;
  text-transform: capitalize;
  margin: 0;
  padding: 1rem 0;

  ${mediaQueries('md')`
    font-size: 3rem;
  `};
`;

export default PageSectionTitle;
