import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';
import { localizeDate } from '../../lib/helpers';
import ViewMoreBtn from '../Buttons/ViewMoreBtn';

const ReviewCard = ({ review, lang, btnText, relatedUrl }) => {
  const { slug, title, publishedAt, source, _rawQuote: quote } = review;

  const localePublishingDate = localizeDate(publishedAt, lang, 'long', null);

  const quoteExcerpt = quote[0].children[0].text.split('\n')[0].replace(/^(.{150}[^\s]*).*/, '$1');

  return (
    <StyledLink to={relatedUrl ? `${relatedUrl}${slug.current}` : slug.current}>
      <Container>
        <StyledCardRow className="card-row">
          <StyledLeftCardCol md={6} className="card-left">
            <StyledCardLeft>
              {/* <StyledCardHeader><time>{localePublishingDate}</time></StyledCardHeader> */}
              <StyledCardBody>
                <StyledCardTitle as="h3">{title}</StyledCardTitle>
              </StyledCardBody>
            </StyledCardLeft>
          </StyledLeftCardCol>
          <StyledRightCardCol md={6} className="card-right">
            <StyledCardRight>
              <StyledCardHeader>
                <time>{localePublishingDate}</time>
              </StyledCardHeader>
              <StyledCardBody>
                <StyledQuoteText>
                  <blockquote>{quoteExcerpt} ...</blockquote>
                </StyledQuoteText>

                <StyledReviewSource>{source}</StyledReviewSource>
              </StyledCardBody>
              <Card.Footer>
                <ViewMoreBtn btnText={btnText} />
              </Card.Footer>
            </StyledCardRight>
          </StyledRightCardCol>
        </StyledCardRow>
      </Container>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  cursor: pointer;
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.black};

    & .view-more-btn {
      &::before {
        width: 56px;
      }
    }
  }
`;

const StyledCardRow = styled(Row)`
  @media (min-width: ${breakpoints.md}px) {
    transform: translateX(1.5rem);
  }
`;

const StyledLeftCardCol = styled(Col)`
  padding: 0;
  order: 1;
  z-index: 1;
  /* margin-top: -4rem;
  margin-bottom: 4rem; */
`;

const StyledRightCardCol = styled(Col)`
  padding: 0;
  order: 2;
  z-index: 2;

  @media (min-width: ${breakpoints.md}px) {
    transform: translate(-3rem, 3rem);
  }
`;

const StyledCardLeft = styled(Card)`
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 0;
  padding-top: 2.6rem;
  padding-right: 4rem;
  padding-bottom: 2.6rem;
  padding-left: 3rem;
  height: 100%;
`;

const StyledCardRight = styled(Card)`
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 0;
  padding-top: 2.6rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
`;

const StyledCardHeader = styled(Card.Header)`
  padding: 0;
  border: 0;
  border-radius: 0 !important;
  background-color: ${(props) => props.theme.colors.transparent};

  & > time {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const StyledCardTitle = styled(Card.Title)`
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 1.3rem;
  line-height: 1.5;
  word-wrap: normal;
`;

const StyledQuoteText = styled.div`
  font-family: ${(props) => props.theme.fonts.serif};
  font-style: italic;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.7rem;
`;

const StyledCardBody = styled(Card.Body)`
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledReviewSource = styled.p`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.05em;
`;

export default ReviewCard;
