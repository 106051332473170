import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const OutlineBtnLg = ({ btnText, toUrl, type, as, handleClick, disabled, href, mailto }) => {
  if (!as) {
    return (
      <StyledGhostBtn to={toUrl} type={type} disabled={disabled}>
        {btnText}
      </StyledGhostBtn>
    );
  } else {
    return (
      <StyledBSBtn
        as={as}
        onClick={handleClick}
        disabled={disabled}
        href={href ? href : ''}
        target={href ? '_blank' : ''}
        mailto={mailto ? mailto : ''}
      >
        {btnText}
      </StyledBSBtn>
    );
  }
};

const StyledBSBtn = styled.button`
  background-color: transparent;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0.125rem;
  border: 2px solid
    ${(props) =>
      (props.type === 'primary' && props.theme.colors.primary) ||
      (props.type === 'white' && props.theme.colors.white) ||
      (props.type === 'black' && props.theme.colors.black)};
  border-radius: 0;
  color: ${(props) =>
    (props.type === 'primary' && props.theme.colors.primary) ||
    (props.type === 'white' && props.theme.colors.white) ||
    (props.type === 'black' && props.theme.colors.black)};

  &:hover,
  &:active {
    border: 2px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    outline: none;
  }

  &[disabled='disabled'],
  &:disabled {
    cursor: not-allowed;
    &:hover,
    &:active {
      color: initial;
      background-color: initial;
      border-color: initial;
      opacity: 0.247;
    }
  }
`;

const StyledGhostBtn = styled(Link)`
  background-color: transparent;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0.125rem;
  border: 2px solid
    ${(props) =>
      (props.type === 'primary' && props.theme.colors.primary) ||
      (props.type === 'white' && props.theme.colors.white) ||
      (props.type === 'black' && props.theme.colors.black)};
  border-radius: 0;
  color: ${(props) =>
    (props.type === 'primary' && props.theme.colors.primary) ||
    (props.type === 'white' && props.theme.colors.white) ||
    (props.type === 'black' && props.theme.colors.black)};

  &:hover,
  &:active {
    border: 2px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    outline: none;
  }

  &[disabled='disabled'],
  &:disabled {
    cursor: not-allowed;
    &:hover,
    &:active {
      color: initial;
      background-color: initial;
      border-color: initial;
      opacity: 0.247;
    }
  }
`;

export default OutlineBtnLg;
